.createPatientFormWrapper {
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.profileImageDiv {
    display: flex;
    flex-direction: column;
    justify-content: start;
}
.fieldsWrapperDiv {
    flex-grow: 1;
}
@media screen and (min-width: 768px) {
    .createPatientFormWrapper {
        flex-direction: row;   
    }
}

.disable-input-border > input {
    border: none;
}